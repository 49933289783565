input[type=text],
input[type=search],
.form-text,
textarea,
select,
button.ui-multiselect {
  border: 1px solid #aaaaaa;
  background-color: #fff;
  padding: 15px 20px;
  outline: 0;
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px !important;
  vertical-align: top;
  border-radius: 0;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
}
input[type=text]:hover,
input[type=search]:hover,
.form-text:hover,
textarea:hover,
select:hover,
button.ui-multiselect:hover {
  border-color: #888888;
}
input[type=text]:active, input[type=text]:focus,
input[type=search]:active,
input[type=search]:focus,
.form-text:active,
.form-text:focus,
textarea:active,
textarea:focus,
select:active,
select:focus,
button.ui-multiselect:active,
button.ui-multiselect:focus {
  border-color: #888888;
}

input[type=text],
input.form-text,
input[type=search] {
  padding-top: 0;
  padding-bottom: 0;
  height: 48px;
  -webkit-appearance: none;
}

select {
  padding-top: 0;
  padding-bottom: 0;
  height: 48px;
}

.form-submit,
button:not(.ui-multiselect) {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  border-color: #017630;
  background-color: #017630;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em;
}
.form-submit:hover,
button:not(.ui-multiselect):hover {
  border-color: #018f3a;
  background-color: #018f3a;
  color: #fff;
}
.form-submit:active, .form-submit:focus,
button:not(.ui-multiselect):active,
button:not(.ui-multiselect):focus {
  border-color: #015d26;
  background-color: #015d26;
  color: #fff;
}
.form-submit::before,
button:not(.ui-multiselect)::before {
  line-height: 1;
}
.form-submit:hover,
button:not(.ui-multiselect):hover {
  text-decoration: none;
}

button.ui-multiselect {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 50px;
  height: 48px;
}

fieldset {
  border: 1px solid #cccccc;
  background: #fff;
  padding-left: 15px;
  padding-right: 15px;
}
fieldset .fieldset-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}
fieldset legend {
  border-radius: 0;
  background: transparent;
  color: #222;
  font-weight: 600;
  display: block;
  padding: 5px;
  font-size: 1.15em;
  margin-left: -5px;
}
fieldset legend a,
fieldset legend a:hover,
fieldset legend a:active,
fieldset legend a:focus {
  color: #222;
  text-decoration: none;
}
fieldset.collapsible .fieldset-title::after {
  font-family: "iconfont";
  content: "\edba";
  font-size: 12px;
  margin-left: 8px;
}
fieldset.collapsed .fieldset-title::after {
  content: "\edc2";
}
fieldset fieldset {
  border: 1px solid #cccccc;
  margin-bottom: 30px;
}
fieldset fieldset:not(.collapsed) {
  box-shadow: 0 1px 0 #fff, 0 1px 0 #fff inset;
}
fieldset fieldset legend {
  font-size: 1em;
}

.lt-ie9 input[type=text],
.lt-ie9 input.form-text {
  max-width: none;
}

*:focus {
  outline: 0;
}

.progress .bar {
  border: 1px solid #aaaaaa;
  background: #fff;
}
.progress .bar .filled {
  background-color: #017630;
}

.form-textarea-wrapper::after {
  clear: both;
  content: " ";
  display: table;
  visibility: hidden;
}
.form-textarea-wrapper textarea {
  resize: none;
  width: 100%;
  max-width: 100%;
}
.form-textarea-wrapper.resizable {
  position: relative;
}
.form-textarea-wrapper.resizable .grippie {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10px;
  background: #cccccc;
}
.form-textarea-wrapper.resizable .grippie::before, .form-textarea-wrapper.resizable .grippie::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 1px;
  background-color: #b3b3b3;
  box-shadow: -1px 0 0 #d9d9d9;
}
.form-textarea-wrapper.resizable .grippie::after {
  top: 5px;
}

html.js input.form-autocomplete {
  background-position: right 15px top 18px;
}

.password-parent,
.confirm-parent {
  margin: 0;
}

button.ui-multiselect {
  width: 100% !important;
  position: relative;
}
button.ui-multiselect.ui-state-active {
  border-color: #017630;
}
button.ui-multiselect .ui-icon {
  position: absolute;
  right: 20px;
  top: 50%;
}
button.ui-multiselect .ui-icon-triangle-1-s {
  content: " ";
  margin-top: -3px;
  border-width: 6px;
  border-style: solid;
  border-color: #222 transparent transparent;
}

.ui-multiselect-menu {
  width: auto;
  background: #fff;
  color: #222;
  padding: 20px;
  box-shadow: 5px 7px 1px -2px rgba(0, 0, 0, 0.4);
  min-width: 235px;
  z-index: 9999;
  position: absolute;
  top: 100%;
  left: 0;
  display: block !important;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  min-width: 280px;
  transition-property: max-height, padding;
  transition-duration: 350ms;
}
.ui-multiselect-menu[style*=top] {
  max-height: 500px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.ui-multiselect-menu[style*=none] {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.ui-multiselect-checkboxes {
  margin: 10px 0 0;
  padding: 0 2px 0 0;
  overflow: auto;
}
.ui-multiselect-checkboxes label {
  cursor: pointer;
  display: block;
  line-height: 1.1em;
  border-bottom: 1px solid #cccccc;
  padding: 8px 4px 8px 28px;
}
.ui-multiselect-checkboxes label:hover {
  color: #fff;
  border-color: #017630;
  background-color: #017630;
}
.ui-multiselect-checkboxes label input {
  margin-left: -23px;
  float: left;
  margin-top: 2px;
  outline: 2px solid #cccccc;
}
.ui-multiselect-checkboxes label input[aria-selected=true] {
  outline: 2px solid #2cfd80;
}
.ui-multiselect-checkboxes li:last-child label {
  border-bottom: 0 none;
}

.ui-multiselect-filter {
  margin-bottom: 5px;
  height: 40px;
  text-indent: -9000px;
}
.ui-multiselect-filter input {
  width: 100% !important;
  height: 40px;
  padding: 0 5px;
  font-size: 12px;
  float: left;
}

.ui-multiselect-header .ui-helper-reset {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.ui-multiselect-header .ui-helper-reset a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent;
  border-color: #aaaaaa;
  color: #222;
  padding: 8px 10px;
  border-radius: 5px;
  line-height: 1em;
  font-size: 12px;
  line-height: 1.05em;
  font-size: 12px;
}
.ui-multiselect-header .ui-helper-reset a:hover {
  background-color: transparent;
  border-color: #bfbfbf;
  color: #017630;
}
.ui-multiselect-header .ui-helper-reset a:active, .ui-multiselect-header .ui-helper-reset a:focus {
  background-color: transparent;
  border-color: #9e9e9e;
  color: #222;
}
.ui-multiselect-header .ui-helper-reset a::before {
  line-height: 1;
}
.ui-multiselect-header .ui-helper-reset a:hover {
  text-decoration: none;
}
.ui-multiselect-header .ui-helper-reset .ui-multiselect-close > .ui-icon {
  font-size: 18px;
}
.ui-multiselect-header .ui-helper-reset .ui-multiselect-close > .ui-icon::before {
  content: "x";
  font-weight: bold;
}

#ui-datepicker-div {
  border-right: 0 none;
  border-bottom: 0 none;
  background: #fff;
  color: #222;
  padding: 20px;
  box-shadow: 5px 7px 1px -2px rgba(0, 0, 0, 0.4);
  min-width: 235px;
  z-index: 9999;
  position: absolute;
  top: 100%;
  left: 0;
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
}
#ui-datepicker-div .ui-datepicker-title {
  line-height: 1.1em;
  margin: 0;
}
#ui-datepicker-div .ui-datepicker-title select {
  padding-left: 3px;
  padding-right: 3px;
}
#ui-datepicker-div .ui-datepicker-header > a {
  height: 30px;
  width: 30px;
  top: 0;
  line-height: 30px;
  border-radius: 50%;
  background: #01672a;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  text-align: center;
}
#ui-datepicker-div .ui-datepicker-header > a .ui-icon {
  display: none;
}
#ui-datepicker-div .ui-datepicker-header > a::before {
  font-family: "iconfont";
  vertical-align: middle;
  text-align: center;
  width: 100%;
}
#ui-datepicker-div .ui-datepicker-header > a.ui-datepicker-prev::before {
  content: "\edc6";
}
#ui-datepicker-div .ui-datepicker-header > a.ui-datepicker-next::before {
  content: "\edbe";
}
#ui-datepicker-div .ui-datepicker-calendar td {
  text-align: center;
}
#ui-datepicker-div .ui-datepicker-calendar td > a {
  height: 30px;
  width: 30px;
  line-height: 24px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
}
#ui-datepicker-div .ui-datepicker-calendar td > a:hover {
  background: #017630;
  color: #fff;
}
#ui-datepicker-div .ui-datepicker-calendar td.ui-datepicker-today > a {
  background-color: #2cfd80;
  color: #fff;
}
#ui-datepicker-div .ui-datepicker-calendar td.ui-state-disabled {
  opacity: 0.5;
}

.addressfield-container-inline {
  max-width: 600px;
  display: flex;
}
.addressfield-container-inline .form-item:first-child {
  width: 30%;
  min-width: 80px;
}
.addressfield-container-inline .form-item:last-child {
  width: 70%;
  margin-right: 0;
}

.form-item {
  margin-top: 0;
}
.form-item label {
  display: block;
  font-weight: normal;
}
.form-item label.option {
  display: inline-block;
  font-weight: 300;
  vertical-align: middle;
}
.form-item .description {
  font-size: 0.85em;
  line-height: normal;
}
.form-item .description p {
  margin-bottom: 0.25em;
}

.inline-fields label {
  display: inline-block;
  width: 30%;
}

.marker,
.form-required {
  color: #d9534f;
}

.auto_upload-processed + .form-submit {
  display: none !important;
}

.tabledrag-handle {
  position: relative;
  z-index: 3;
  width: 41px;
}
.tabledrag-handle .handle {
  box-sizing: content-box;
}

table.tabledrag-processed {
  width: 100%;
}

tr.draggable > td {
  padding-top: 15px;
  border-bottom: 1px solid #cccccc;
}
tr.draggable > td:first-child {
  padding-left: 56px;
  position: relative;
}
tr.draggable > td:first-child .tabledrag-handle {
  margin-left: -56px;
}
tr.draggable > td.checkbox, tr.draggable > td:last-child {
  text-align: center;
}
tr.draggable .form-item {
  border: 0 none;
  box-shadow: none;
  padding-bottom: 0;
}
tr.draggable.drag td {
  opacity: 0.8;
  background-color: #fae3c4;
}
tr.draggable.drag-previous td {
  background-color: #fae3c4;
}
tr.draggable .tabledrag-changed {
  color: #fae3c4;
  display: block;
  width: 20px;
  background: #b06d0f;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0 50% 0 0;
  height: 20px;
  text-align: center;
  line-height: 26px;
  vertical-align: middle;
}

.image-widget {
  padding-left: 125px;
  position: relative;
}
.image-widget .filefield-sources-list {
  margin-left: -125px;
}
.image-widget .image-preview {
  float: left;
  width: 110px;
  margin-left: -125px;
}
.image-widget .form-file {
  margin-top: 7.5px;
  margin-left: -125px;
}

.container-inline div,
.container-inline label {
  display: inline;
}

.form-actions {
  padding-top: 0.5em;
}
.form-actions::after {
  clear: both;
  content: " ";
  display: table;
  visibility: hidden;
}
.form-actions input {
  margin-right: 5px;
}

form.node-form #edit-submit,
.webform-client-form .form-submit {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  border-color: #017630;
  background-color: #017630;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em;
}
form.node-form #edit-submit:hover,
.webform-client-form .form-submit:hover {
  border-color: #018f3a;
  background-color: #018f3a;
  color: #fff;
}
form.node-form #edit-submit:active, form.node-form #edit-submit:focus,
.webform-client-form .form-submit:active,
.webform-client-form .form-submit:focus {
  border-color: #015d26;
  background-color: #015d26;
  color: #fff;
}
form.node-form #edit-submit::before,
.webform-client-form .form-submit::before {
  line-height: 1;
}
form.node-form #edit-submit:hover,
.webform-client-form .form-submit:hover {
  text-decoration: none;
}

form.node-form #edit-delete {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: #d9534f;
  border-color: #d9534f;
  text-shadow: 0 1px 1px #e48481;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em;
}
form.node-form #edit-delete:hover {
  background-color: #dc5f5c;
  border-color: #dc5f5c;
  color: #fff !important;
}
form.node-form #edit-delete:active, form.node-form #edit-delete:focus {
  background-color: #d64742;
  border-color: #d64742;
  color: #a02622;
}
form.node-form #edit-delete::before {
  line-height: 1;
}
form.node-form #edit-delete:hover {
  text-decoration: none;
}

body.page-node-edit {
  padding-bottom: 30px;
}

form.node-form .form-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: 1px solid #e2e2e2;
  text-align: center;
  z-index: 99;
  padding: 5px 0 10px;
  margin: 0;
}
form.node-form .form-actions .form-submit {
  margin-right: 3px !important;
}

#user-profile-form input[type=text],
#user-profile-form .form-text,
#user-profile-form textarea,
#user-profile-form .form-textarea-wrapper textarea,
#user-profile-form select,
.form-item-mollom-captcha input[type=text] {
  width: auto;
}

.node-form .form-item,
.node-form .field-type-text-long-form,
.node-form .field-type-text-with-summary {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.node-form .form-item .form-item,
.node-form .field-type-text-long-form .form-item,
.node-form .field-type-text-with-summary .form-item {
  border: 0 none;
  box-shadow: none;
  padding-bottom: 0;
}

.views-row .field-type-text-long-form,
.views-row .field-type-text-with-summary,
.field-type-text-with-summary .field-type-text-with-summary,
.field-type-text-long-form .form-item,
.field-type-text-with-summary .form-item,
.node-form .field-type-text-long-form .form-item,
.node-form .field-type-text-with-summary .form-item {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0 none;
  box-shadow: none;
}

.form-item + .description,
.form-item .description {
  font-size: 12px;
  padding: 0.5em 0;
  color: #222;
  line-height: 1.35;
  font-style: italic;
}
.form-item + .description::before,
.form-item .description::before {
  font-family: "iconfont";
  font-weight: normal !important;
  font-style: normal !important;
  margin-right: 4px;
  content: "\ed64";
}

.form-item:hover + .description,
.form-item:hover > .description,
.form-item .description:hover {
  color: #222;
}

.fieldset-description {
  padding: 15px 15px 15px 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  position: relative;
  color: #1f7e9a;
  background-color: #c5e9f3;
  border-color: #5bc0de;
}
.fieldset-description::before {
  font-family: "iconfont";
  position: absolute;
  left: 15px;
  top: 14px;
  font-size: 25px;
  line-height: normal;
}
.fieldset-description ul {
  margin-top: 0;
  margin-bottom: 0;
}
.fieldset-description a {
  color: #1f7e9a;
}
.fieldset-description::before {
  content: "\ed64";
}

.form-type-radio .description,
.form-type-checkbox .description {
  margin-left: 0;
}

.form-checkboxes .form-item,
.form-radios .form-item {
  float: none;
  padding: 0.75em 0 3px 1%;
  margin: 0;
  line-height: normal;
}

.form-checkboxes > .form-item,
.form-type-bef-checkbox {
  appearance: none;
}
.form-checkboxes > .form-item input[type=checkbox],
.form-type-bef-checkbox input[type=checkbox] {
  width: auto;
  float: left;
  margin-right: 0.75em;
  background: transparent;
  border: none;
}
.form-checkboxes > .form-item input[type=checkbox]:checked, .form-checkboxes > .form-item input[type=checkbox]:not(:checked),
.form-type-bef-checkbox input[type=checkbox]:checked,
.form-type-bef-checkbox input[type=checkbox]:not(:checked) {
  background: transparent;
  position: relative;
  visibility: hidden;
  display: none;
  margin: 0;
  padding: 0;
}
.form-checkboxes > .form-item input[type=checkbox]:checked + label::before,
.form-type-bef-checkbox input[type=checkbox]:checked + label::before {
  background: #fff;
  content: "\ed72";
}
.form-checkboxes > .form-item input[type=checkbox] + label,
.form-type-bef-checkbox input[type=checkbox] + label {
  cursor: pointer;
  vertical-align: top;
}
.form-checkboxes > .form-item input[type=checkbox] + label::before,
.form-type-bef-checkbox input[type=checkbox] + label::before {
  content: " ";
  display: inline-block;
  width: 24px;
  height: 24px;
  font-family: "iconfont";
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
  position: relative;
  top: 0;
  border: 1px solid #aaaaaa;
  background: #fff;
  margin-right: 0.35em;
}
.form-checkboxes > .form-item input[type=checkbox]:hover + label::before,
.form-type-bef-checkbox input[type=checkbox]:hover + label::before {
  background: #fff;
  border-color: #888888;
  box-shadow: inset 0 0 0 2px #fff;
}

.date-padding {
  display: flex;
}
.date-padding label {
  color: #aaaaaa;
}
.date-padding .form-item {
  margin-right: 15px;
  margin-bottom: 0;
}

.date-no-float:last-child {
  margin-bottom: 0;
}

.form-media .preview:empty {
  display: none;
}

.filter-guidelines {
  margin: 10px;
}
.filter-guidelines .tips {
  padding: 10px 0 0;
}

.media-wrapper {
  z-index: 999999;
}

.search-form {
  margin: 0;
}
.search-form .container-inline {
  display: table;
  display: flex;
}
.search-form .container-inline > * {
  display: table-cell;
}
.search-form .container-inline .form-actions {
  padding: 0;
  margin: 0;
}
.search-form .container-inline .form-actions .form-submit {
  margin: 0;
}
.search-form .form-type-searchfield {
  position: relative;
  margin-right: 2px;
}
.search-form .form-type-searchfield::before {
  font-family: "iconfont";
  font-weight: normal !important;
  font-style: normal !important;
  margin-right: 4px;
  content: "\eb36";
  color: #888888;
}
.search-form .form-type-searchfield::before {
  position: absolute;
  top: 50%;
  line-height: 1em;
  margin-top: -9px;
  left: 7px;
}
.search-form .form-text {
  padding-left: 27px;
}
.search-form:hover .form-item::before {
  color: #888888;
}

#edit-advanced .fieldset-legend {
  margin-left: -10px;
}
#edit-advanced .fieldset-legend a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  border-color: #017630;
  background-color: #017630;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em;
}
#edit-advanced .fieldset-legend a:hover {
  border-color: #018f3a;
  background-color: #018f3a;
  color: #fff;
}
#edit-advanced .fieldset-legend a:active, #edit-advanced .fieldset-legend a:focus {
  border-color: #015d26;
  background-color: #015d26;
  color: #fff;
}
#edit-advanced .fieldset-legend a::before {
  line-height: 1;
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  margin-right: 5px;
  text-align: center;
}
#edit-advanced .fieldset-legend a:hover {
  text-decoration: none;
}

.search-results {
  margin: 0;
  padding: 0;
}
.search-results .search-result {
  border-bottom: 1px solid #aaaaaa;
}
.search-results .search-result .title {
  margin-top: 0;
  margin-bottom: 0.25em;
}

#user-register-form fieldset,
#edit-register fieldset {
  border-width: 1px;
  border-style: solid;
  padding: 30px 30px;
  border-color: #cccccc;
  border-radius: 8px;
  background-color: #fff;
}
#user-register-form fieldset:before,
#edit-register fieldset:before, #user-register-form fieldset:after,
#edit-register fieldset:after {
  display: table;
  content: " ";
}
#user-register-form fieldset:after,
#edit-register fieldset:after {
  clear: both;
}
#user-register-form #edit-submit,
#edit-register #edit-submit {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: #5cb85c;
  border-color: #5cb85c;
  color: #fff;
  padding: 18.75px 25px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em;
}
#user-register-form #edit-submit:hover,
#edit-register #edit-submit:hover {
  background-color: #67bd67;
  border-color: #67bd67;
  color: #fff;
}
#user-register-form #edit-submit:active,
#edit-register #edit-submit:active, #user-register-form #edit-submit:focus,
#edit-register #edit-submit:focus {
  background-color: #51b351;
  border-color: #51b351;
  color: #357935;
}
#user-register-form #edit-submit::before,
#edit-register #edit-submit::before {
  line-height: 1;
}
#user-register-form #edit-submit:hover,
#edit-register #edit-submit:hover {
  text-decoration: none;
}

#edit-register {
  border-width: 1px;
  border-style: solid;
  padding: 30px 30px;
  border-color: #cccccc;
  border-radius: 8px;
  background-color: #fff;
  margin-top: 30px;
}
#edit-register:before, #edit-register:after {
  display: table;
  content: " ";
}
#edit-register:after {
  clear: both;
}

.not-logged-in.page-user .page-title::before,
.not-logged-in.page-toboggan-denied .page-title::before {
  font-family: "iconfont";
  font-weight: normal !important;
  font-style: normal !important;
  margin-right: 4px;
  content: "\eb50";
}

#edit-subscriptions {
  border-width: 1px;
  border-style: solid;
  padding: 30px 30px;
  border-color: #cccccc;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 1em;
}
#edit-subscriptions:before, #edit-subscriptions:after {
  display: table;
  content: " ";
}
#edit-subscriptions:after {
  clear: both;
}
#edit-subscriptions .form-text {
  width: 100%;
}
#edit-subscriptions .fieldset-wrapper {
  padding-top: 0;
}
#edit-subscriptions #edit-newsletters {
  margin-bottom: 1em;
}

#user-profile-form {
  border-width: 1px;
  border-style: solid;
  padding: 30px 30px;
  border-color: #cccccc;
  border-radius: 8px;
  background-color: #fff;
}
#user-profile-form:before, #user-profile-form:after {
  display: table;
  content: " ";
}
#user-profile-form:after {
  clear: both;
}

.pane-user-simplenews h3 {
  margin: 0;
  font-size: 1em;
}
.pane-user-simplenews dl,
.pane-user-simplenews dd {
  margin: 0;
}

.webform-confirmation {
  border: 1px solid #5cb85c;
  font-weight: 600;
  color: #47a447;
  padding: 1rem;
  margin-bottom: 1rem;
}

.webform-client-form .form-submit {
  float: right;
}
.webform-client-form .form-submit.button-primary {
  font-weight: 600;
}
.webform-client-form fieldset {
  margin-bottom: 30px;
}
.webform-client-form .form-actions {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: -1rem;
}
.webform-client-form .form-actions::after {
  display: none;
}
.webform-client-form .form-actions > * {
  flex: 1 1 auto;
  float: none;
  margin: 1rem;
  text-align: center;
}
@media (min-width: 992px) {
  .webform-client-form .form-actions > * {
    flex-grow: 0;
  }
}
@media (min-width: 992px) {
  .webform-client-form .form-actions > *:only-child,
  .webform-client-form .form-actions > * + *:last-of-type {
    margin-left: auto;
  }
}
.webform-client-form .form-actions .ajax-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.page-node-done .links {
  margin-top: 1em;
}
.page-node-done .links a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  border-color: #017630;
  background-color: #017630;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em;
}
.page-node-done .links a:hover {
  border-color: #018f3a;
  background-color: #018f3a;
  color: #fff;
}
.page-node-done .links a:active, .page-node-done .links a:focus {
  border-color: #015d26;
  background-color: #015d26;
  color: #fff;
}
.page-node-done .links a::before {
  line-height: 1;
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  margin-right: 5px;
  text-align: center;
  content: "\edda";
}
.page-node-done .links a:hover {
  text-decoration: none;
}

.webform-container-inline {
  display: flex;
}

.webform-component--ihre-nachricht legend,
.webform-component--kontakt legend {
  position: relative;
  padding-left: 40px;
}
.webform-component--ihre-nachricht legend::before,
.webform-component--kontakt legend::before {
  position: absolute;
  left: 0;
  top: 0;
  font-family: "iconfont";
  line-height: 33px;
  width: 33px;
  height: 33px;
  background: #017630;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  border-radius: 100%;
  content: "\e922";
}

@media (min-width: 992px) {
  .webform-component--ihre-nachricht {
    float: left;
    width: calc(50% - 15px);
    margin-right: 15px;
  }
}

.webform-component--kontakt {
  content: "\eaf7";
}
@media (min-width: 992px) {
  .webform-component--kontakt {
    float: left;
    width: calc(50% - 15px);
    margin-left: 15px;
  }
}
.webform-component--kontakt legend::before {
  content: "\eaf7";
}

.webform-component--datenschutz {
  clear: both;
}

#form-destination input[type=text] {
  padding: 5px;
  margin-bottom: 5px;
}
@media (min-width: 992px) {
  #form-destination input[type=text] {
    width: 64%;
    margin-right: 1%;
    margin-bottom: 0;
    float: left;
  }
}
#form-destination .form-submit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  width: 100%;
}
@media (min-width: 992px) {
  #form-destination .form-submit {
    width: 35%;
    float: left;
  }
}

.webform-progressbar {
  margin-bottom: 0.75rem;
}

.webform-progressbar-outer {
  border: 0 none;
  width: auto;
  height: auto;
  counter-reset: progress-bar;
  font-size: 1rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  user-select: none;
}
@media only screen and (min-width:992px) {
  .webform-progressbar-outer {
    flex-direction: row;
  }
}
.webform-progressbar-outer .webform-progressbar-inner {
  display: none !important;
}
.webform-progressbar-outer .webform-progressbar-page {
  position: static;
  width: auto;
  height: auto;
  border: 0 none;
  background-color: white;
  border-radius: 0;
  display: flex;
  align-items: center;
  margin-left: 15px;
  padding-right: 30px;
  margin: 0 0 0.5rem 0;
  flex-grow: 1;
}
@media only screen and (min-width:992px) {
  .webform-progressbar-outer .webform-progressbar-page {
    margin-bottom: 0;
    margin-right: 15px;
  }
}
.webform-progressbar-outer .webform-progressbar-page::before {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: #222;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75em;
  margin-left: -15px;
  counter-increment: progress-bar;
  content: counter(progress-bar);
  transform: scale(1.1);
}
@media only screen and (min-width:992px) {
  .webform-progressbar-outer .webform-progressbar-page:not(:nth-child(1)) {
    margin-left: -15px;
  }
}
@media only screen and (min-width:992px) {
  .webform-progressbar-outer .webform-progressbar-page:last-child {
    padding-right: 15px;
    margin-right: 0;
    border-radius: 0 8px 8px 0;
  }
}
.webform-progressbar-outer .webform-progressbar-page .webform-progressbar-page-label {
  position: static;
  margin: 0;
}
.webform-progressbar-outer .webform-progressbar-page.current {
  background: #017630;
  color: #fff;
}
.webform-progressbar-outer .webform-progressbar-page.completed {
  background-color: #777777;
  color: #fff;
}
.webform-progressbar-outer .webform-progressbar-page.completed::before {
  font-family: "iconfont";
  content: "\ed72";
}

.webform-previous:not(:hover) {
  background-color: white;
  border-color: white;
  color: #222;
}

.block--click-and-collect-form {
  border: 1px solid #cccccc;
  padding: 1.5rem;
  margin-bottom: 1rem;
}
.block--click-and-collect-form .node {
  margin: 0;
}
.block--click-and-collect-form .node .node-title {
  display: flex;
  align-items: center;
  margin: 0 -1.5rem 0.5em -1.5rem;
  padding: 0 1.5rem 1rem 1.5rem;
  border-bottom: 1px solid #cccccc;
}
.block--click-and-collect-form .node .node-title::before {
  font-family: "iconfont";
  content: "\e9fe";
  flex: 0 0 42px;
  margin-right: 0.65rem;
  width: 42px;
  height: 42px;
  background: #017630;
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-indent: -3px;
}
.block--click-and-collect-form .webform-client-form fieldset {
  margin-bottom: 1rem;
}
.form-checkboxes > .form-item,
.form-type-bef-checkbox{
  @if($styled-checkboxes == true){
    appearance: none;
    input[type='checkbox']{
      width:auto;
      float:left;
      margin-right: .75em;
      background:transparent;
      border:none;
      &:checked,
      &:not(:checked){
        background: transparent;
        position: relative;
        visibility: hidden;
        display: none; // the hidden box takes some place - hide it completely
        margin:0;
        padding:0;
      }
      &:checked{
        + label{
          &::before{
            background:$form-item-background;
            @include get-ico-only('check');
          }
        }
      }
      + label{
        cursor: pointer;
        vertical-align: top;
        &::before{
          content:' ';
          display:inline-block;
          width: $styled-checkboxes-size;
          height:$styled-checkboxes-size;
          font-family:$iconfont;
          text-align:center;
          vertical-align: middle;
          line-height: $styled-checkboxes-size;
          position: relative;
          top:0; // default 4px - why?
          border: 1px solid $form-item-border;
          background: $form-item-background;
          margin-right: .35em;
          // box-shadow: inset 0 1px 1px 0 rgba(0,0,0,.1);
        }
      }
      &:hover{
        + label{
          &::before{
            background:$form-item-background;
            border-color:$form-item-border-hover;
            box-shadow: inset 0 0 0 2px $white;
          }
        }
      }
    }
  }
}

@import "base";

// =============================================================================
//  Element Styling
// =============================================================================

$input-block-max-width: 600px;

input[type="text"],
input[type="search"],
.form-text,
textarea,
select,
button.ui-multiselect{
  border:1px solid $form-item-border;
  background-color:$form-item-background;
  padding:$input-default-v-space $input-default-h-space;
  outline:0;
  text-align:left;
  font-size:$input-default-font-size;
  font-weight: $font-weight-normal;
  line-height:$input-default-line-height !important;
  vertical-align:top;
  border-radius:0; // TODO: Variable fuer input border radius einfuehren
  width:100%;
  max-width:$input-block-max-width;
  box-sizing: border-box;
  &:hover{
    border-color:$form-item-border-hover;
  }
  &:active,
  &:focus{
    border-color:$form-item-border-active;
  }
}

input[type="text"],
input.form-text,
input[type="search"]{
  // fix different height to text fields
  padding-top:0;
  padding-bottom:0;
  height:($input-default-v-space * 2) + $input-default-line-height; // 2px = Border
  // height:($btn-default-v-space * 2) + $input-default-font-size + 2px; // fit button height, 2px = border
  // Fix round corners on ios
  -webkit-appearance: none;
}
select{
  // There is no valid 'auto', 'initial' value to reset the appearance setting to the default value. So ...
  // Same prodedure as above, but without -webkit-appearance fix for damn ios.
  padding-top:0;
  padding-bottom:0;
  height:($input-default-v-space * 2)+$input-default-line-height; // 2px = Border
}

.form-submit,
button:not(.ui-multiselect){
  @include btn($btn-default-style, $btn-default-size);
}

button.ui-multiselect{
  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  padding-right:50px;
  height:($input-default-v-space * 2) + $input-default-line-height; // 2px = Border
  // max-width:150px;
}

// Fieldset
fieldset {
  border:1px solid $grey-light;
  background:$white;
  padding-left:$grid / 2;
  padding-right:$grid / 2;
  .fieldset-wrapper{
    padding-top:0;
    padding-bottom:0;
  }
  legend{
    border-radius:0;
    background:transparent;
    color:$text-color;
    font-weight:$font-weight-bold;
    display:block;
    padding:5px;
    font-size:1.15em;
    margin-left:-5px;
    a,
    a:hover,
    a:active,
    a:focus{
      color:$text-color;
      text-decoration:none;
    }
  }
  &.collapsible{
    .fieldset-title{
      &::after{
        font-family:$iconfont;
        @include get-ico-only('arrow-thin-top');
        font-size:$icon-size-xs;
        margin-left:8px;
      }
    }
  }
  &.collapsed{
    .fieldset-title{
      &::after{
        @include get-ico-only('arrow-thin-bottom');
      }
    }
  }
  fieldset{
    border:1px solid $grey-light;
    margin-bottom: $grid-vertical;
    &:not(.collapsed){
      box-shadow:0 1px 0 $white, 0 1px 0 $white inset;
    }
    legend{
      font-size:1em;
    }
  }
}

// IE-FIX
.lt-ie9 input[type="text"],
.lt-ie9 input.form-text{
  max-width:none;
}

// Chrome-FIXES
*:focus {
  // Remove this ugly outline thing on focused elements
  outline: 0;
}

// =============================================================================
//   Special Form Items
// =============================================================================

// Progress Bars
.progress .bar{
  border:1px solid $grey;
  background:$white;
  .filled{
    background-color:$highlight;
  }
}

// Textarea Grippie
.form-textarea-wrapper{
  @include clearfix-after;
  textarea{
    resize: none;
    width:100%;
    max-width:100%;
  }
  &.resizable{
    position: relative;
    .grippie {
      position:absolute;
      left:0;
      right:0;
      bottom:0;
      height:10px;
      background: $grey-light;
      &::before,
      &::after{
        content:"";
        position:absolute;
        top:3px;
        left:50%;
        margin-left:-10px;
        width:20px;
        height:1px;
        background-color: darken($grey-light, 10%);
        box-shadow: -1px 0 0 lighten($grey-light, 5%);
      }
      &::after{
        top:5px;
      }
    }
  }
}

// Autocomplete Fields
html.js input.form-autocomplete{
  background-position: right $thin-grid top ($input-default-v-space + 3);
}

// Password confirmation
.password-parent,
.confirm-parent {
  margin: 0;
}

// jQuery Widgets
// -- Select & Multiselect
@import 'components/jquery_multiselect';

// Date Picker
@import 'components/forms_datepicker';

// Address Fields
.addressfield-container-inline{
  max-width:$input-block-max-width;
  display: flex;
  .form-item{
    &:first-child{
      width:30%;
      min-width:80px;
    }
    &:last-child{
      width:70%;
      margin-right:0;
    }
  }
}

// =============================================================================
//   Drupal Forms
// =============================================================================

.form-item{
  margin-top:0;
  // Label
  label {
    display: block;
    font-weight: normal;
    &.option { // The label for a radio button or checkbox
      display: inline-block;
      font-weight: $font-weight-normal;
      vertical-align:middle;
    }
  }
  // Description
  .description {
    font-size: .85em;
    line-height:normal;
    p{
      margin-bottom: .25em;
    }
  }
}
.inline-fields label{ // Inline styled Form Fields
  display:inline-block;
  width:30%;
}

// Highlight marks and form required mark
.marker,
.form-required {
  color: $danger;
}

// File Fields
// -- Auto Upload
.auto_upload-processed + .form-submit{
  display:none !important;
}

// Table (draggable) Fields
.tabledrag-handle{
  position:relative;
  z-index:3;
  width:$tabledrag-icon-width;
  .handle{
    box-sizing: content-box;
  }
}
table.tabledrag-processed{
  width:100%;
}
tr.draggable{
  > td{
    padding-top:$thin-grid;
    border-bottom:1px solid $grey-light;
    &:first-child{
      padding-left:$tabledrag-icon-width + $thin-grid;
      position:relative;
      .tabledrag-handle{
        margin-left:($tabledrag-icon-width + $thin-grid) * -1;
      }
    }
    &.checkbox,
    &:last-child{
      text-align:center;
    }
  }
  .form-item{
    // reset regular field style
    border:0 none;
    box-shadow:none;
    padding-bottom:0;
  }
  &.drag{
    // While dragging
    td{
      opacity: .8;
      background-color:$message-warning-background;
    }
  }
  &.drag-previous{
    // Dragged none saved item
    td{
      background-color:$message-warning-background;
    }
  }
  .tabledrag-changed{
    // Element that marks an changed tr
    color:$message-warning-background;
    display:block;
    width:20px;
    background:$message-warning-text;
    position:absolute;
    left:0;
    bottom:0;
    border-radius:0 50% 0 0;
    height: 20px;
    text-align:center;
    line-height: 26px;
    vertical-align: middle;
  }
}

// Image Fields
.image-widget{
  padding-left:$image-preview-width + ($grid / 2);
  position:relative;
  .filefield-sources-list{
    margin-left:($image-preview-width + ($grid / 2)) * -1;
  }
  .image-preview{
    float:left;
    width:$image-preview-width;
    margin-left:-($image-preview-width + ($grid / 2));
  }
  .form-file{
    margin-top:$input-default-v-space / 2;
    margin-left:($image-preview-width + ($grid / 2)) * -1;
  }
}

// Misc
.container-inline div,
.container-inline label {
  display: inline;
}

.form-actions{
  padding-top: .5em;
  @include clearfix-after;
  input{
    margin-right:5px;
  }
}

form.node-form #edit-submit,
.webform-client-form .form-submit{
  @include btn('highlight');
}

form.node-form #edit-delete{
  @include btn('red');
}

@if $dock-form-actions == true{
  body.page-node-edit{ padding-bottom:30px; }
  form.node-form .form-actions{
    position:fixed;
    bottom:0;
    left:0;
    width:100%;
    background:$white;
    border-top:1px solid $webks-grey-light;
    text-align:center;
    z-index:99;
    padding:5px 0 10px;
    margin:0;
    .form-submit{
      margin-right:3px !important;
    }
  }
}

// Drupal Default Forms
#user-profile-form input[type="text"],
#user-profile-form .form-text,
#user-profile-form textarea,
#user-profile-form .form-textarea-wrapper textarea, /* Override System.css */
#user-profile-form select,
.form-item-mollom-captcha input[type="text"]{ width:auto; }

// Textareas
.node-form .form-item,
.node-form .field-type-text-long-form,
.node-form .field-type-text-with-summary{
  @include cutline('bottom');
  .form-item{
    border:0 none;
    box-shadow:none;
    padding-bottom:0;
  }
}

// Remove cutlines
.views-row .field-type-text-long-form,
.views-row .field-type-text-with-summary,
.field-type-text-with-summary .field-type-text-with-summary,
.field-type-text-long-form .form-item,
.field-type-text-with-summary .form-item,
.node-form .field-type-text-long-form .form-item,
.node-form .field-type-text-with-summary .form-item{
  margin-bottom:0;
  padding-bottom:0;
  border:0 none;
  box-shadow:none;
}

// Descriptions
.form-item + .description,
.form-item .description{ // The descriptive help text (separate from the label)
  font-size: $font-small;
  padding: .5em 0;
  color:$form-description-color;
  line-height:$line-height-medium;
  font-style:italic;
  @include ico('circle-info');
}
.form-item:hover + .description,
.form-item:hover > .description,
.form-item .description:hover {
  color:$text-color;
}

.fieldset-description{
  @include message(info);
}

.form-type-radio .description,
.form-type-checkbox .description { // Reset margin for radius & checkboxes
  margin-left: 0;
}
.form-checkboxes .form-item,
.form-radios .form-item{ // Pack groups of checkboxes and radio buttons closer together
  float:none;
  padding: .75em 0 3px 1%;
  margin:0;
  line-height:normal;
}

// Styled form components
@import 'components/styled-form-compentents';

// Date Fields
.date-padding{
  display: flex;
  label{
    color:$grey;
  }
  .form-item{
    margin-right:$thin-grid;
    margin-bottom:0;
  }
}
.date-no-float{
  &:last-child{
    margin-bottom:0;
  }
}

// Media Fields
.form-media{
  .preview{
    &:empty{
      display:none;
    }
  }
}

// Office Hours Fields
// @import "components/_module-opening-hours";

// =============================================================================
//   Form Specific
// =============================================================================

// View Filter Style
.filter-guidelines{
  margin:10px;
  .tips{
    padding:10px 0 0;
  }
}

// Media overlay bugfix
.media-wrapper{
  z-index:999999;
}

// ALL Search Forms
.search-form{
  margin:0;
  // @include clearfix-after;
  .container-inline{
    @include inline-form(".form-type-searchfield", ".form-actions");
  }
  .form-type-searchfield{
    position:relative;
    margin-right:2px;
    @include ico('search', $grey-dark);
    &::before{
      position: absolute;
      top: 50%;
      line-height:1em;
      margin-top:($icon-size-normal / 2) * -1;
      left: 7px;
    }
  }
  .form-text{
    padding-left:27px; // reserved space for the icon
  }
  &:hover .form-item::before{
    color:$grey-dark;
  }
}

// Search (Block)
#edit-advanced{
  .fieldset-legend{
    margin-left:-10px;
    a{
      @include btn('default', 'default', 'process');
    }
  }
}

// Search (Page)
.search-results{
  margin:0;
  padding:0;
  .search-result{
    border-bottom:1px solid $grey;
    .title{
      margin-top:0;
      margin-bottom: .25em;
    }
  }
}

// Registrierung

%user-register-form{
  fieldset{
    @include box-style;
  }
  #edit-submit{
    @include btn('green', 'xl');
  }
}

#user-register-form,
#edit-register{
  @extend %user-register-form;
}

#edit-register{
  // Register forms inside node forms (for non registred users)
  @include box-style();
  margin-top:$grid-vertical;
}

// Login
.not-logged-in.page-user .page-title,
.not-logged-in.page-toboggan-denied .page-title {
  @include ico('locked');
}

// Newsletter Subscriptions
#edit-subscriptions{
  @include box-style;
  margin-bottom:1em;
  .form-text{
    width:100%;
  }
  .fieldset-wrapper{
    padding-top:0;
  }
  #edit-newsletters{
    margin-bottom:1em;
  }
}

// User Profile
#user-profile-form{
  @include box-style;
}
.pane-user-simplenews{
  h3{
    margin:0;
    font-size:1em;
  }
  dl,
  dd{
    margin:0;
  }
}

// =============================================================================
//   Webforms
// =============================================================================
.webform-confirmation{
  border: 1px solid $sucess;
  font-weight: $font-weight-bold;
  color: darken($sucess, 8%);
  padding:1rem;
  margin-bottom:1rem;
}
.webform-client-form{
  // @include box-style;
  .form-submit{
    float:right;
    &.button-primary{
      font-weight: $font-weight-bold;
    }
  }
  fieldset{
    margin-bottom:$grid-vertical;
  }
  .form-actions{
    position:relative;
    display:flex;
    justify-content: space-between;
    flex-flow:row wrap;
    margin:-1rem;
    &::after{
      display:none;
    }
    > *{
      flex: 1 1 auto;
      float:none;
      margin:1rem;
      text-align: center;
      @include media-breakpoint-up(lg){
        flex-grow:0;
      }
      &:only-child,
      + *:last-of-type{
        @include media-breakpoint-up(lg){
          margin-left:auto;
        }
      }
    }
    .ajax-progress{
      position:absolute;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
      z-index:1;
      width:32px;
      height:32px;
      display:flex;
      align-items:center;
      justify-content:center;
      border-radius:50%;
      background:white;
      box-shadow: 0 0 5px rgba(0,0,0,.1);
    }
  }
}
// Make "Back to form"-Link a button
.page-node-done .links {
  margin-top:1em;
  a{
    @include btn ('default', 'default', 'arrow-left');
  }
}

.webform-container-inline{
  display:flex;
}

// Form Specific
// -- Kontakt
.webform-component--ihre-nachricht,
.webform-component--kontakt{
  legend{
    position:relative;
    padding-left:40px;
    &::before{
      position:absolute;
      left:0;
      top:0;
      font-family:"iconfont";
      line-height:33px;
      width:33px;
      height:33px;
      background:$highlight;
      color:$highlight-text-color;
      vertical-align:middle;
      text-align:center;
      border-radius:100%;
      @include get-ico-only('form');
    }
  }
}
.webform-component--ihre-nachricht{
  @include media-breakpoint-up(lg){
    float:left;
    width:calc(50% - #{$half-grid});
    margin-right:$half-grid;
  };
}
.webform-component--kontakt{
  @include get-ico-only('user');
  @include media-breakpoint-up(lg){
    float:left;
    width:calc(50% - #{$half-grid});
    margin-left:$half-grid;
  };
  legend{
    &::before{
      @include get-ico-only('user');
    }
  }
}
.webform-component--datenschutz{
  clear:both;
}

// -- -- Anfahrt widget
#form-destination{
  input[type="text"]{
    padding:5px;
    margin-bottom:5px;
    @include media-breakpoint-up(lg){
      width:64%;
      margin-right:1%;
      margin-bottom:0;
      float:left;
    }
  }
  .form-submit{
    white-space:nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    height:auto;
    width:100%;
    @include media-breakpoint-up(lg){
      width:35%;
      float:left;
    }
  }
}

// "Checkout" (Multistep order request webform)
.webform-progressbar{
  margin-bottom:.75rem;
}
.webform-progressbar-outer{
  border: 0 none;
  width:auto;
  height:auto;
  counter-reset: progress-bar;
  font-size:1rem;
  list-style:none;
  display:flex;
  flex-direction:column;
  margin:0;
  user-select:none;
  @media #{$large-up}{
    flex-direction:row;
  }
  .webform-progressbar-inner{
    display:none !important;
  }
  .webform-progressbar-page{
    position:static;
    width:auto;
    height:auto;
    border:0 none;
    background-color: $grey-lighter;
    border-radius:0;
    display:flex;
    align-items:center;
    margin-left:15px;
    padding-right:30px;
    margin:0 0 .5rem 0;
    flex-grow:1;
    @media #{$large-up}{
      margin-bottom:0;
      margin-right:15px;
    }
    &::before{
      height:30px;
      width:30px;
      border-radius:50%;
      color: $text-color;
      background-color:$white;
      box-shadow: 0 0 5px rgba(0,0,0,.1);
      font-weight:$font-weight-bold;
      display:flex;
      justify-content: center;
      align-items: center;
      margin-right:.75em;
      margin-left:-15px;
      counter-increment: progress-bar;
      content: counter(progress-bar);
      transform: scale(1.1);
    }
    &:not(:nth-child(1)){
      @media #{$large-up}{
        margin-left:-15px;
      }
    }
    &:last-child{
      @media #{$large-up}{
        padding-right:15px;
        margin-right:0;
        border-radius:0 $border-radius $border-radius 0;
      }
    }
    .webform-progressbar-page-label{
      position:static;
      margin:0;
    }
    // States
    &.current{
      background:$highlight;
      color:$highlight-text-color;
      &::before{

      }
    }
    &.completed{
      background-color:$grey-darker;
      color: $white;
      &::before{
        font-family:$iconfont;
        content:"\ed72"; // check
      }
    }
  }
}
.webform-previous{
  &:not(:hover){
    background-color: $grey-lighter;
    border-color: $grey-lighter;
    color: $text-color;
  }
}

// Click and collect form
.block--click-and-collect-form{
  border:1px solid $grey-light;
  padding:1.5rem;
  margin-bottom:1rem;
  .node{
    margin:0;
    .node-title{
      display:flex;
      align-items:center;
      margin: 0 -1.5rem .5em -1.5rem;
      padding: 0 1.5rem 1rem 1.5rem;
      border-bottom: 1px solid $grey-light;
      &::before{
        font-family: $iconfont;
        @include get-ico-only('cart');
        flex: 0 0 42px;
        margin-right:.65rem;
        width: 42px;
        height: 42px;
        background: $highlight;
        color: $white;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-indent: -3px;
      }
    }
  }
  .webform-client-form{
    fieldset{
      margin-bottom:1rem;
    }
  }
}
